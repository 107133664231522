import {
  Dialog,
  DialogContent,
  DialogProps,
  SxProps,
  Theme,
} from "@mui/material";

type ModalProps = DialogProps & {
  sx?: SxProps<Theme>;
};

export const StyledModal = ({ children, ...rest }: ModalProps) => {
  return (
    <Dialog
      {...rest}
      fullWidth={true}
      PaperProps={{
        sx: {
          borderRadius: "1rem",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 4px 16px",
          maxWidth: "28rem",
          width: "100%",
        },
      }}
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          padding: "2rem",          
        })}
        {...rest}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
